import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function WeightGraph({ weights }) {
  const [timeRange, setTimeRange] = useState(30);

  const filteredData = weights
    .filter(entry => {
      const entryDate = entry.date.toDate();
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - timeRange);
      return entryDate >= cutoffDate;
    })
    .map(entry => ({
      date: entry.date.toDate(),
      weight: entry.weight
    }))
    .sort((a, b) => a.date - b.date);

  const minWeight = Math.min(...filteredData.map(d => d.weight));
  const maxWeight = Math.max(...filteredData.map(d => d.weight));
  const yAxisDomain = [Math.floor(minWeight - 1), Math.ceil(maxWeight + 1)];

  return (
    <div className="weight-graph">
      <div className="time-range-buttons">
        <button onClick={() => setTimeRange(7)} className={timeRange === 7 ? 'active' : ''}>7 days</button>
        <button onClick={() => setTimeRange(15)} className={timeRange === 15 ? 'active' : ''}>15 days</button>
        <button onClick={() => setTimeRange(30)} className={timeRange === 30 ? 'active' : ''}>30 days</button>
        <button onClick={() => setTimeRange(90)} className={timeRange === 90 ? 'active' : ''}>90 days</button>
      </div>
      <div className="graph-container">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={filteredData} margin={{ top: 5, right: 5, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              tickFormatter={(value) => {
                const date = new Date(value);
                return `${date.getDate()}/${date.getMonth() + 1}`;
              }}
              tick={{ fontSize: 12 }}
            />
            <YAxis 
              domain={yAxisDomain} 
              tick={{ fontSize: 12 }} 
              width={30}
            />
            <Tooltip 
              labelFormatter={(value) => new Date(value).toLocaleDateString()}
              formatter={(value) => [`${value} kg`, 'Weight']}
            />
            <Line 
              type="monotone" 
              dataKey="weight" 
              stroke="#007AFF" 
              strokeWidth={2}
              dot={{ r: 3 }}
              activeDot={{ r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default WeightGraph;