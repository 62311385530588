export function calculateWeightPlan(
  currentWeight,
  weightGoal,
  height,
  age,
  gender,
  bodyType,
  lifestyle
) {
  // Constants
  const KG_TO_LBS = 2.20462;
  const CM_TO_INCHES = 0.393701;

  // Convert height to inches
  const heightInches = height * CM_TO_INCHES;

  // Calculate Ideal Body Weight (IBW)
  let ibw;
  if (gender === 'male') {
    ibw = 50 + 2.3 * (heightInches - 60);
  } else {
    ibw = 45.5 + 2.3 * (heightInches - 60);
  }

  // Adjust IBW for muscular build
  if (bodyType === 'muscular') {
    ibw *= 1.1;
  }

  // Determine goal weight
  let goalWeight;
  if (weightGoal) {
    goalWeight = parseFloat(weightGoal);
  } else {
    goalWeight = Math.round(ibw);
  }

  // Calculate BMR for the current weight
  let bmr;
  if (gender === 'male') {
    bmr = 10 * currentWeight + 6.25 * height - 5 * age + 5;
  } else {
    bmr = 10 * currentWeight + 6.25 * height - 5 * age - 161;
  }

  // Activity level multipliers
  const lifestyleFactors = {
    sedentary: 1.2,
    moderatelyActive: 1.375,
    veryActive: 1.55
  };

  // Calculate TDEE
  let tdee = bmr * lifestyleFactors[lifestyle];

  // Determine if weight loss or gain is needed
  const isWeightLoss = currentWeight > goalWeight;
  const calorieAdjustment = isWeightLoss ? -500 : 500;
  const adjustedCalories = Math.round(tdee + calorieAdjustment);

  // Calculate macros
  const proteinRatio = 0.40;
  const carbRatio = 0.40;
  const fatRatio = 0.20;

  const proteinGrams = Math.round((adjustedCalories * proteinRatio) / 4);
  const carbGrams = Math.round((adjustedCalories * carbRatio) / 4);
  const fatGrams = Math.round((adjustedCalories * fatRatio) / 9);

  // Calculate time to reach goal
  const weightDifference = Math.abs(currentWeight - goalWeight);
  const weeksToGoal = Math.ceil(weightDifference / 0.5); // 0.5 kg per week

  return {
    currentWeight,
    goalWeight,
    idealBodyWeight: Math.round(ibw),
    dailyCalories: adjustedCalories,
    macros: {
      protein: { grams: proteinGrams, percentage: Math.round(proteinRatio * 100) },
      carbs: { grams: carbGrams, percentage: Math.round(carbRatio * 100) },
      fat: { grams: fatGrams, percentage: Math.round(fatRatio * 100) }
    },
    estimatedWeeksToGoal: weeksToGoal,
    weightChangeDirection: isWeightLoss ? 'loss' : 'gain'
  };
}